import { Flex, Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import { Button } from 'components/fromAdvisor/elements'
import { Switch } from 'components/fromAdvisor/elements/Switch'
import { ChartWrapperProps, useChartWrapper } from './hook'

const ChartWrapper: FC<ChartWrapperProps> = props => {
  const {
    label,
    valueSwitch,
    isBorderLeft = false,
    isShowButtonZoom = true,
    handleOpenZoom,
    setValueSwitch,
  } = useChartWrapper(props)
  return (
    <>
      <Text
        borderLeft={isBorderLeft ? '2px solid #0084FF' : 'none'}
        fontSize="18px"
        fontWeight="bold"
        lineHeight="shorter"
        minW="fit-content"
        px="8px"
      >
        {label}
      </Text>
      {isShowButtonZoom && (
        <Flex
          alignItems={'center'}
          gap={'6px'}
          justifyContent={'space-between'}
        >
          <Switch
            cusbgcolor={'#0084FF'}
            isChecked={valueSwitch}
            onChange={() => {
              setValueSwitch(prev => !prev)
            }}
          />
          <Button
            _hover={{
              bgColor: 'white.50',
            }}
            bgColor="white.50"
            border="solid 1px"
            borderColor="blue.900"
            borderRadius="12px"
            color="blue.900"
            fontSize="sm"
            fontWeight="bold"
            h="32px"
            lineHeight="shorter"
            minW={'103px'}
            padding="8px 16px"
            text="全画面表示"
            onClick={handleOpenZoom}
          />
        </Flex>
      )}
    </>
  )
}

export default ChartWrapper
