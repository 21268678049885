export type ChartWrapperProps = {
  label: string
  handleOpenZoom: () => void
  isBorderLeft?: boolean
  isShowButtonZoom?: boolean
  valueSwitch: boolean
  setValueSwitch: React.Dispatch<React.SetStateAction<boolean>>
}

export const useChartWrapper = (props: ChartWrapperProps) => {
  return { ...props }
}
