/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex, Text } from '@chakra-ui/layout'
import { SelectField } from 'components/atoms'
import { FormikProvider } from 'formik'
import React, { FC } from 'react'
import { Switch } from 'components/fromAdvisor/elements/Switch'
import { CircularProgress, SimpleGrid } from '@chakra-ui/react'
import { Button } from 'components/fromAdvisor/elements'
import { Option } from 'types/conmon'
import CustomMultiSelect from 'components/atoms/MultiSelectField/CustomMultiSelect'
import WordCloud from '../WordCloud'
// import Echarts from '../Echarts'
import { ModalZoom } from '../WordMap/ModalZoom'
import ChartWrapper from '../ChartWrapper'
import RenderActionOpenDrawer from './RenderActionOpenDrawer'
import { NewsDetailWordMapProps, useNewsDetailWordMap } from './hook'
import ExplanationAi from './ExplanationAi'
import { optionDisplaySize } from './constant'
import AiExplainAndZoomAll from './AiExplainAndZoomAll'
import RenderWordMap from './RenderWordMap'
import WordMapOptions from './WordMapOptions'

const NewsDetailWordMap: FC<NewsDetailWordMapProps> = props => {
  const {
    formik,
    isZoom,
    values,
    typeNews,
    dataChart,
    infoModal,
    reportType,
    valueSwitch,
    companyName,
    industryName,
    isNotWordmap,
    isOpenDrawer,
    dataChartMore,
    displaySizeChart,
    valueExplanation,
    isLoadingDataChart,
    containerChartWidth,
    multiCompanyOptions,
    optionsAnalysisMenu,
    containerChartHeight,
    handleSwitch,
    setDataChart,
    setFieldValue,
    handleOpenZoom,
    handleResetForm,
    handleOffSwitch,
    handleCloseZoom,
    setDataChartMore,
    handleSaveImproveWordCloudData,
    handleBackToInitialWordCloudData,
  } = useNewsDetailWordMap(props)
  return (
    <FormikProvider value={formik}>
      <Box p={'12px 16px'} w={'full'}>
        <Flex
          alignItems={'center'}
          gap={'12px'}
          justify={'space-between'}
          pb={'12px'}
        >
          <Flex gap="16px">
            <SelectField
              height="40px"
              name="analysis_menu"
              options={optionsAnalysisMenu}
              placeholder="--"
              valueOption={optionsAnalysisMenu.find(
                it => it.value === values.analysis_menu,
              )}
              width="auto"
              onChange={e => handleResetForm(e)}
            />
            {values.analysis_menu === '1' && (
              <>
                <CustomMultiSelect
                  defaultValue={values.multi_company}
                  labelEmptyOption="比較する企業がありません"
                  labelTooltip="項目は２つまで選択可能です"
                  options={multiCompanyOptions}
                  placeholder="会社名を選択"
                  setValue={(options: Option[]) =>
                    setFieldValue('multi_company', options)
                  }
                />
              </>
            )}
            {values.analysis_menu === '2' && (
              <RenderActionOpenDrawer
                companyId={props.companyId}
                handleOffSwitch={handleOffSwitch}
                quarter={props.quarter ?? 0}
                reportType={reportType}
                setFieldValue={setFieldValue}
                values={values}
                year={props.year}
              />
            )}
          </Flex>
          {((values.analysis_menu === '2' && !isOpenDrawer) ||
            values.analysis_menu !== '2') && (
            <AiExplainAndZoomAll
              handleOpenZoom={handleOpenZoom}
              handleSwitch={handleSwitch}
              isNotWordmap={isNotWordmap}
              isShowZoomAll={values.analysis_menu !== '0'}
              valueSwitch={valueSwitch}
            />
          )}
        </Flex>
        {values.analysis_menu !== '0' && (
          <WordMapOptions
            handleOffSwitch={handleOffSwitch}
            handleOpenZoom={handleOpenZoom}
            handleSwitch={handleSwitch}
            isNotWordmap={isNotWordmap}
            isOpenDrawer={isOpenDrawer}
            setDataChart={setDataChart}
            setDataChartMore={setDataChartMore}
            setFieldValue={setFieldValue}
            values={values}
            valueSwitch={valueSwitch}
          />
        )}
        {valueSwitch && (
          <ExplanationAi
            showContent={valueSwitch}
            valueExplanation={valueExplanation}
          />
        )}
        <RenderWordMap
          companyName={companyName}
          containerChartHeight={containerChartHeight}
          containerChartWidth={containerChartWidth}
          dataChart={dataChart}
          dataChartMore={dataChartMore}
          displaySizeChart={displaySizeChart}
          handleBackToInitialWordCloudData={handleBackToInitialWordCloudData}
          handleOpenZoom={handleOpenZoom}
          handleSaveImproveWordCloudData={handleSaveImproveWordCloudData}
          industryName={industryName}
          isLoading={isLoadingDataChart}
          isNotWordmap={isNotWordmap}
          reportType={reportType}
          values={values}
        />
      </Box>
      <ModalZoom
        analysisChart={values.analysis_menu}
        dataChart={dataChart}
        dataChartMore={dataChartMore}
        height={containerChartHeight}
        infoModal={infoModal}
        isOpen={isZoom}
        width={containerChartWidth}
        onClose={handleCloseZoom}
      />
    </FormikProvider>
  )
}

export default NewsDetailWordMap
