import React, { FC, ReactNode } from 'react'
import {
  Box,
  Flex,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as TabsChakra,
  Tab,
  TabIndicator,
  Image,
  Text,
} from '@chakra-ui/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'
import { icons } from 'constant'
import { CardRanking } from '../CardRanking'
import { CardPostContent } from '../CardPostContent'
import { AIAdvice } from '../AIAdvice'
import { NewsRelated } from '../NewsRelated'
import NewsDetailWordMap from '../NewsDetailWordMap'
import { ArticleSummary } from '../ArticleSummary'
import { ContentAskAI } from '../ContentAskAI'
import { PostProps, usePost } from './hook'

export type TabsType = {
  id: number
  hidden?: boolean
  label?: string
  component?: ReactNode
  isDisabled?: boolean
}
/**
 * Post molecules
 * @constructor
 */
export const Post: FC<PostProps> = props => {
  const {
    formik,
    page,
    setPage,
    isOpenDrawer,
    onOpenDrawer,
    newsDetail,
    id,
    setNewsDetail,
    onCloseDrawer,
    indexTab,
    handleChangeTab,
    typeNews,
    cunrentPage,
    setCunrentPage,
    handleSetPagePdf,
    summaryContent,
  } = usePost(props)

  const TabItems: TabsType[] = [
    {
      hidden: true,
      id: 1,
      label: '記事要約',
      component: typeNews ? (
        <ArticleSummary
          avatar=""
          handleSetPagePdf={handleSetPagePdf}
          newsId={id}
          question=""
          onChangeInput={() => console.log()}
          onEnter={() => console.log()}
          onSubmit={() => console.log()}
        />
      ) : (
        <Flex justify={'center'} p={'12px 16px'}>
          {Boolean(summaryContent.data) ? (
            <ContentAskAI
              content={summaryContent}
              heightContent="calc(100vh - 184px)"
              isCopyBtn={false}
              isDone={true}
              isHidden={false}
              isOpenModal={true}
              isValues={true}
            />
          ) : (
            <Text fontSize={'16px'}>データがありません。</Text>
          )}
        </Flex>
      ),
    },
    {
      hidden: true,
      id: 2,
      label: 'AIアドバイス',
      component: <AIAdvice newsId={id} />,
    },
    {
      hidden: !typeNews,
      id: 3,
      label: '取引可能性',
      component: (
        <CardRanking
          showFilterDate
          id={id}
          isColumn={true}
          separator={true}
          showScoreChange={false}
        />
      ),
    },
    {
      hidden: true,
      id: 4,
      label: 'ワードマップ',
      component: (
        <NewsDetailWordMap
          companyId={newsDetail.data.company_id}
          companyName={newsDetail.data.subject_company}
          industryName={newsDetail.data.category}
          isOpenDrawer={isOpenDrawer}
          quarter={newsDetail.data.company_report?.quarter ?? 0}
          reportType={newsDetail.data.company_report?.report_type ?? 1}
          typeNews={typeNews}
          year={newsDetail.data.company_report?.year}
        />
      ),
    },
    {
      hidden: true,
      id: 5,
      label: '関連記事',
      component: <NewsRelated newsId={id} />,
    },
  ]

  return (
    <Flex
      alignItems="flex-start"
      gap={isOpenDrawer ? 4 : 0}
      minH="calc( 100vh - 88px )"
      w="100%"
    >
      <Box
        bg={isOpenDrawer ? 'white.base' : ''}
        borderRadius={4}
        boxSizing="border-box"
        display={isOpenDrawer ? 'block' : 'flex'}
        height="100%"
        justifyContent={isOpenDrawer ? 'unset' : 'center'}
        padding={isOpenDrawer ? '12px 24px' : '0 24px 0 0'}
        w={isOpenDrawer ? '50%' : 'auto'}
      >
        <Box
          cursor="pointer"
          display={isOpenDrawer ? 'none' : 'block'}
          mt="20px"
          onClick={onOpenDrawer}
        >
          <Image src={icons.ICON_DRAWER_OPEN} />
        </Box>
        <Box display={isOpenDrawer ? 'block' : 'none'}>
          <CardPostContent
            cunrentPage={cunrentPage}
            formik={formik}
            handleSetPagePdf={handleSetPagePdf}
            newsDetail={newsDetail}
            newsId={id}
            page={page}
            setCunrentPage={setCunrentPage}
            setNewsDetail={setNewsDetail}
            setPage={setPage}
            onCloseDrawer={onCloseDrawer}
          />
        </Box>
      </Box>
      <Flex
        bg="white.base"
        borderRadius={4}
        direction="column"
        height="100%"
        w={isOpenDrawer ? '50%' : '100%'}
      >
        <TabsChakra
          isLazy
          index={indexTab}
          variant="unstyled"
          onChange={handleChangeTab}
        >
          <TabList>
            {TabItems.filter(t => t.hidden === true).map(
              ({ id, label, isDisabled }) => (
                <Tab
                  key={id}
                  _selected={{
                    color: '#141718',
                    fontWeight: 500,
                  }}
                  borderBottom={'none'}
                  borderTopLeftRadius={'3xl'}
                  color={'#0084FF'}
                  fontSize={'sm'}
                  fontWeight={'normal'}
                  h="48px"
                  isDisabled={isDisabled}
                  w={34}
                >
                  {label}
                </Tab>
              ),
            )}
          </TabList>
          <TabIndicator bg="#0084FF" borderRadius="1px" height="2px" />
          <TabPanels>
            {TabItems.filter(t => t.hidden === true).map(
              ({ id, component }) => (
                <TabPanel key={id} p={0}>
                  {component}
                </TabPanel>
              ),
            )}
          </TabPanels>
        </TabsChakra>
      </Flex>
    </Flex>
  )
}
