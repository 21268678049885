/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, CircularProgress, Flex, useToast } from '@chakra-ui/react'
import ChartWrapper from 'components/molecules/ChartWrapper'
import WordCloud from 'components/molecules/WordCloud'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { companyServices } from 'services'
import { WordCloudType } from 'services/companyServices'

type WordMapChartProps = {
  key: string
  handleOpenZoom: () => void
  data: {
    title: string
    words_cloud: WordCloudType[]
  }
  width: number
  height: number
  keySearch?: string
  isShowButtonZoom?: boolean
  typeOfProcess: string
  ngram: string
  industryName?: string
  companyName?: string
  handleSaveImproveWordCloudData: (data: WordCloudType[]) => void
  handleBackToInitialWordCloudData: () => void
}

const WordMapChart: FC<WordMapChartProps> = ({
  key,
  handleOpenZoom,
  data,
  width,
  height,
  keySearch,
  isShowButtonZoom = true,
  companyName,
  industryName,
  ngram,
  typeOfProcess,
  handleSaveImproveWordCloudData,
  handleBackToInitialWordCloudData,
}) => {
  const [valueSwitch, setValueSwitch] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const handleImproveWordCloudData = useCallback(async () => {
    if (!valueSwitch) {
      handleBackToInitialWordCloudData()
    } else {
      if (!companyName || !industryName) return
      setIsLoading(true)
      try {
        const response = await companyServices.getImproveWordmap({
          ngram: +ngram,
          type_of_process: +typeOfProcess,
          chart_data: data.words_cloud,
          industry_name: industryName,
          company_name: companyName,
        })
        handleSaveImproveWordCloudData(response.data.chart_data)
        setIsLoading(false)
      } catch (error) {
        setValueSwitch(false)
        setIsLoading(false)
        toast({
          description:
            'An error occurred while improving the data. Please try again.',
          status: 'error',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName, industryName, toast, typeOfProcess, valueSwitch])

  useEffect(() => {
    handleImproveWordCloudData()
  }, [handleImproveWordCloudData])

  return (
    <Box key={key} w={'100%'}>
      <Flex align={'center'} justify={'space-between'} mb={'8px'}>
        <ChartWrapper
          handleOpenZoom={handleOpenZoom}
          isShowButtonZoom={isShowButtonZoom}
          label={data.title}
          setValueSwitch={setValueSwitch}
          valueSwitch={valueSwitch}
        />
      </Flex>
      <Box
        bgColor={'#FEFEFE'}
        borderRadius={'12px'}
        height={`${height}px`}
        width={`${width}px`}
      >
        {isLoading ? (
          <Flex alignItems={'center'} h={'full'} justify={'center'} w={'full'}>
            <CircularProgress isIndeterminate color="blue.900" size={10} />
          </Flex>
        ) : (
          <WordCloud
            height={height}
            keySearch={keySearch}
            width={width}
            words={data.words_cloud}
          />
        )}
      </Box>
    </Box>
  )
}

export default WordMapChart
