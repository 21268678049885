import React, { FC } from 'react'
import { Flex, Box, Text, SimpleGrid, CircularProgress } from '@chakra-ui/react'
import { FormikProvider } from 'formik'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'
import { Switch } from 'components/fromAdvisor/elements/Switch'
import { Button } from 'components/fromAdvisor/elements'
// import Echarts from '../Echarts'
import WordMapChart from '../WordMapChart'
import { WordMapProps, useWordMap } from './hook'
import WordMapOptions from './WordMapOptions'
import AIExplanation from './AIExplanation'
import { ModalZoom } from './ModalZoom'
import FieldWordMapWrapper from './FieldWordMapWrapper'

/**
 * WordMap molecules
 * @constructor
 */
const WordMap: FC<WordMapProps> = props => {
  const {
    isZoom,
    formik,
    values,
    infoModal,
    dataChart,
    valueSwitch,
    infoCompany,
    isNotWordmap,
    dataChartMore,
    displaySizeChart,
    valueExplanation,
    isLoadingDataChart,
    multiCompanyOptions,
    containerChartWidth,
    containerChartHeight,
    setValues,
    handleSwitch,
    setDataChart,
    setFieldValue,
    setValueSwitch,
    handleOpenZoom,
    handleOffSwitch,
    handleCloseZoom,
    setDataChartMore,
    setValueExplanation,
    handleResetDataChart,
    handleSaveImproveWordCloudData,
    handleBackToInitialWordCloudData,
  } = useWordMap(props)
  return (
    <FormikProvider value={formik}>
      <Box bg="white.base" borderRadius={4} w="100%">
        <Flex
          alignItems="center"
          h={'64px'}
          justifyContent="space-between"
          px={'24px'}
          w="100%"
        >
          <Flex alignItems="center" h={'full'} w="100%">
            <Text
              color="black.100"
              display="inline-block"
              fontSize="lg"
              fontWeight="bold"
              letterSpacing="-0.02em"
              lineHeight="tall"
              minW="fit-content"
              px="8px"
            >
              ワードマップ
            </Text>
            <FieldWordMapWrapper
              handleOffSwitch={handleOffSwitch}
              handleResetDataChart={handleResetDataChart}
              setFieldValue={setFieldValue}
              setValueExplanation={setValueExplanation}
              setValues={setValues}
              setValueSwitch={setValueSwitch}
              values={values}
            />
          </Flex>
          <Flex align={'center'} gap={'6px'} minW={'fit-content'}>
            <Text
              fontSize="14px"
              fontWeight="normal"
              lineHeight="14px"
              minW="fit-content"
            >
              AI解説
            </Text>
            <Switch
              cusbgcolor={'#0084FF'}
              isChecked={valueSwitch}
              onChange={() => {
                handleSwitch()
              }}
            />
            {values.analysis_menu !== '0' && (
              <Button
                _hover={{
                  bgColor: 'white.50',
                }}
                bgColor="white.50"
                border="solid 1px"
                borderColor="blue.900"
                borderRadius="12px"
                color="blue.900"
                fontSize="sm"
                fontWeight="bold"
                h="40px"
                isDisabled={isNotWordmap}
                lineHeight="shorter"
                padding="8px 16px"
                text="全画面表示"
                onClick={() => {
                  handleOpenZoom({
                    isShowAll: true,
                    typeChart: '',
                    index: null,
                  })
                }}
              />
            )}
          </Flex>
        </Flex>
        <Flex
          bg="#F3F5F7"
          borderRadius={'0 0 20px 20px'}
          gap={4}
          h={'full'}
          minH={'164px'}
          p={'16px 24px 24px 24px'}
          w="100%"
        >
          {isNotWordmap && !isLoadingDataChart && (
            <Text
              color={'black.100'}
              fontSize={'16px'}
              textAlign={'center'}
              w="full"
            >
              データがありません。
            </Text>
          )}
          {isLoadingDataChart && (
            <Flex alignItems={'center'} justify={'center'} w={'full'}>
              <CircularProgress isIndeterminate color="blue.900" size={10} />
            </Flex>
          )}
          {values.analysis_menu === '0' && !isNotWordmap && (
            <Flex direction={'column'}>
              <WordMapChart
                key={'重要なワードを見える化'}
                companyName={infoCompany?.company_name}
                data={{
                  title: '重要なワードを見える化',
                  words_cloud: dataChart.wordLesChart.data,
                }}
                handleBackToInitialWordCloudData={() =>
                  handleBackToInitialWordCloudData('wordLesChart', 0)
                }
                handleOpenZoom={() =>
                  handleOpenZoom({
                    isShowAll: false,
                    typeChart: 'wordcloud',
                    index: 0,
                  })
                }
                handleSaveImproveWordCloudData={data =>
                  handleSaveImproveWordCloudData('wordLesChart', 0, data)
                }
                height={containerChartHeight}
                industryName={infoCompany?.industry_name}
                isShowButtonZoom={true}
                ngram={values.number_of_word}
                typeOfProcess={values.targetDocument}
                width={containerChartWidth}
              />
              {Boolean(values.analysis_menu === '0') && (
                <AIExplanation
                  showContent={valueSwitch}
                  valueExplanation={valueExplanation}
                />
              )}
              {/* {Boolean(!dataChart.wordLesChart.dataEchart.length) ? (
                <></>
              ) : isLoadingDataChart ? (
                <Box
                  bgColor={'#FEFEFE'}
                  borderRadius={'16px'}
                  h={'396px'}
                  mb="8px"
                  w={'full'}
                ></Box>
              ) : (
                <Box w="50%">
                  <Flex align={'center'} justify={'space-between'}>
                    <ChartWrapper
                      handleOpenZoom={() => {
                        handleOpenZoom({
                          isShowAll: false,
                          typeChart: 'echart',
                          index: null,
                        })
                      }}
                      label="各ワード同士の繋がり"
                    />
                  </Flex>
                  <Box
                    bgColor={'#FEFEFE'}
                    borderRadius={'12px'}
                    h={552}
                    mb="8px"
                    w={'full'}
                  >
                    <Echarts
                      data={dataChart.wordLesChart.dataEchart}
                      reloadChart={reloadChart}
                    />
                  </Box>
                  {Boolean(values.analysis_menu === '0') && (
                    <AIExplanation
                      content={valueExplanation.data[1]}
                      loading={valueExplanation.loading}
                      showContent={valueSwitch}
                    />
                  )}
                </Box>
              )} */}
            </Flex>
          )}
          {values.analysis_menu !== '0' && !isNotWordmap && (
            <Flex direction={'column'} w={'100%'}>
              <AIExplanation
                showContent={valueSwitch}
                showDivider={true}
                valueExplanation={valueExplanation}
              />
              <WordMapOptions
                analysisMenuValue={values.analysis_menu}
                handleOffSwitch={handleOffSwitch}
                multiCompanyOptions={multiCompanyOptions}
                setDataChart={setDataChart}
                setDataChartMore={setDataChartMore}
                setFieldValue={setFieldValue}
                values={values}
              />
              <SimpleGrid
                columns={displaySizeChart}
                spacing={'16px'}
                w={'100%'}
              >
                {(values.analysis_menu === '1'
                  ? dataChart.dataListWordCloud.data
                  : dataChart.dataTimeLine.data
                ).map((item, index) => (
                  <WordMapChart
                    key={item.title}
                    companyName={infoCompany?.company_name}
                    data={item}
                    handleBackToInitialWordCloudData={() =>
                      handleBackToInitialWordCloudData(
                        values.analysis_menu === '1'
                          ? 'dataListWordCloud'
                          : 'dataTimeLine',
                        index,
                      )
                    }
                    handleOpenZoom={() => {
                      handleOpenZoom({
                        isShowAll: false,
                        typeChart: 'wordcloud',
                        index: index,
                      })
                    }}
                    handleSaveImproveWordCloudData={data =>
                      handleSaveImproveWordCloudData(
                        values.analysis_menu === '1'
                          ? 'dataListWordCloud'
                          : 'dataTimeLine',
                        index,
                        data,
                      )
                    }
                    height={containerChartHeight}
                    industryName={infoCompany?.industry_name}
                    keySearch={values.search_key}
                    ngram={values.number_of_word}
                    typeOfProcess={values.targetDocument}
                    width={containerChartWidth}
                  />
                ))}
                {dataChartMore.data.map((item, index) => (
                  <WordMapChart
                    key={item.title}
                    companyName={infoCompany?.company_name}
                    data={item}
                    handleBackToInitialWordCloudData={() =>
                      handleBackToInitialWordCloudData(
                        'dataChartMore',
                        dataChart.dataListWordCloud.data.length + index,
                      )
                    }
                    handleOpenZoom={() => {
                      handleOpenZoom({
                        isShowAll: false,
                        typeChart: 'wordcloud',
                        index: dataChart.dataListWordCloud.data.length + index,
                      })
                    }}
                    handleSaveImproveWordCloudData={data =>
                      handleSaveImproveWordCloudData(
                        'dataChartMore',
                        dataChart.dataListWordCloud.data.length + index,
                        data,
                      )
                    }
                    height={containerChartHeight}
                    industryName={infoCompany?.industry_name}
                    keySearch={values.search_key}
                    ngram={values.number_of_word}
                    typeOfProcess={values.targetDocument}
                    width={containerChartWidth}
                  />
                ))}
              </SimpleGrid>
            </Flex>
          )}
        </Flex>
      </Box>
      <ModalZoom
        analysisChart={values.analysis_menu}
        dataChart={dataChart}
        dataChartMore={dataChartMore}
        height={containerChartHeight}
        infoModal={infoModal}
        isOpen={isZoom}
        width={containerChartWidth}
        onClose={handleCloseZoom}
      />
    </FormikProvider>
  )
}

export default React.memo(WordMap)
