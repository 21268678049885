import { useFormik } from 'formik'
import { Option } from 'types/conmon'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { companyServices, storageServices } from 'services'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useDisclosure } from '@chakra-ui/react'
import { DataChartMoreType, DataChartType, InfoModalType } from 'types/chart'
import {
  CompanyInfoResponse,
  WordCategoryChartResponse,
  WordCloudType,
  WordLesChartResponse,
  WordTimeLineChartResponse,
} from 'services/companyServices'

export type WordMapProps = {}

export interface FormValues {
  term: string
  sort_by: number
  quarters: string
  targetYear: string
  search_key: string
  display_size: string
  analysis_menu: string
  multi_company: Option[]
  targetDocument: string
  number_of_word: string
}

export const useWordMap = (props: WordMapProps) => {
  const { id } = useParams()
  const abortControllerRef = useRef<AbortController>(new AbortController())
  const accessToken = storageServices.getAccessToken()

  const formik = useFormik<FormValues>({
    initialValues: {
      term: '',
      sort_by: 1,
      quarters: '',
      targetYear: '',
      search_key: '',
      display_size: '0',
      analysis_menu: '0',
      multi_company: [],
      number_of_word: '2',
      targetDocument: '',
    },
    /**
     *
     */
    onSubmit() {},
  })

  const { values, setFieldValue, setValues } = formik
  const [infoCompany, setInfoCompany] = useState<CompanyInfoResponse>()
  const [valueSwitch, setValueSwitch] = useState(false)
  const [multiCompanyOptions, setMultiCompanyOptions] = useState<Option[]>([])
  const {
    isOpen: isZoom,
    onOpen: onOpenZoom,
    onClose: onCloseZoom,
  } = useDisclosure()

  const [dataChart, setDataChart] = useState<DataChartType>({
    wordLesChart: {
      data: [],
      dataEchart: [],
      hashed_data: '',
    },
    dataListWordCloud: {
      data: [],
      hashed_data: '',
    },
    dataTimeLine: {
      data: [],
      hashed_data: '',
    },
  })

  const [isLoadingDataChart, setIsLoadingDataChart] = useState(false)

  const [dataChartMore, setDataChartMore] = useState<DataChartMoreType>({
    loading: false,
    data: [],
    hashed_data: '',
  })

  const [dataChartMoreInit, setDataChartMoreInit] = useState<
    {
      title: string
      words_cloud: WordCloudType[]
    }[]
  >([])

  const [responseDataWordCloud, setResponseDataWordCloud] = useState<{
    option1: WordLesChartResponse | null
    option2: WordCategoryChartResponse | null
    option3: WordTimeLineChartResponse[]
  }>({
    option1: null,
    option2: null,
    option3: [],
  })

  const [infoModal, setInfoModal] = useState<InfoModalType>({
    isShowAll: false,
    typeChart: '',
    index: null,
  })

  const [valueExplanation, setValueExplanation] = useState<{
    loading: boolean
    data: string
    done: boolean
  }>({ loading: false, data: '', done: true })

  const [windowWidthSize, setWindowWidthSize] = useState(0)

  const isNotWordmap = React.useMemo(
    () =>
      !dataChartMore.data.length &&
      !dataChart.dataTimeLine.data.length &&
      !dataChart.dataListWordCloud.data.length &&
      !dataChart.wordLesChart.dataEchart.length &&
      !dataChart.wordLesChart.data.length,
    [
      dataChartMore.data.length,
      dataChart.dataTimeLine.data.length,
      dataChart.dataListWordCloud.data.length,
      dataChart.wordLesChart.dataEchart.length,
      dataChart.wordLesChart.data.length,
    ],
  )

  const displaySizeChart = React.useMemo(() => {
    switch (values.display_size) {
      case '0':
        return 2
      case '1':
        return 3
      case '2':
        return 5

      default:
        return 2
    }
  }, [values.display_size])

  const gapChart = React.useMemo(
    () => (values.analysis_menu === '0' ? 0 : 16 * (displaySizeChart - 1)),
    [displaySizeChart, values.analysis_menu],
  )

  const containerChartWidth = React.useMemo(() => {
    const width =
      values.analysis_menu === '0'
        ? windowWidthSize - 96 - gapChart
        : (windowWidthSize - 96 - gapChart) / displaySizeChart

    return width
  }, [displaySizeChart, gapChart, values.analysis_menu, windowWidthSize])

  const containerChartHeight = React.useMemo(
    () => containerChartWidth / 1.58,
    [containerChartWidth],
  )

  const isExplanation = React.useMemo(() => {
    if (values.analysis_menu === '2') {
      if (['2', '5'].includes(values.targetDocument)) {
        if (!values.targetYear || !values.term) return false
      } else {
        if (!values.targetYear || !values.quarters || !values.term) return false
      }
    } else {
      if (['2', '5'].includes(values.targetDocument)) {
        if (!values.targetYear) return false
      } else {
        if (!values.targetYear || !values.quarters) return false
      }
    }

    return true
  }, [
    values.term,
    values.quarters,
    values.targetYear,
    values.analysis_menu,
    values.targetDocument,
  ])

  const fileType = useMemo(() => {
    switch (values.analysis_menu) {
      case '0':
        return 'file_path'
      case '1':
        return 'company_industry'
      case '2':
        return 'company_year'
      default:
        return 'file_path'
    }
  }, [values.analysis_menu])

  const fetchCompanyInfo = useCallback(async () => {
    try {
      if (!id) return
      const { data } = await companyServices.getCompanyInfo({
        company_id: id,
        mkt_date: moment().format('YYYY-MM-DD'),
      })
      setInfoCompany(data)
    } catch (error) {}
  }, [id])

  const hashedDataChart = useMemo(() => {
    switch (values.analysis_menu) {
      case '0':
        return dataChart.wordLesChart.hashed_data

      case '1':
        return (
          dataChart.dataListWordCloud.hashed_data + dataChartMore.hashed_data
        )

      case '2':
        return dataChart.dataTimeLine.hashed_data

      default:
        return ''
    }
  }, [
    values.analysis_menu,
    dataChartMore.hashed_data,
    dataChart.dataTimeLine.hashed_data,
    dataChart.wordLesChart.hashed_data,
    dataChart.dataListWordCloud.hashed_data,
  ])

  const payloadSendWebsocket = useMemo(() => {
    let payloadMoreChart = dataChartMore.data.map(item => ({
      [item.title]: item.words_cloud,
    }))

    const result = payloadMoreChart.reduce((acc, cur) => {
      return { ...acc, ...cur }
    }, {})

    switch (values.analysis_menu) {
      case '0':
        return {
          les_miserable: dataChart.wordLesChart.dataEchart,
          word_cloud: dataChart.wordLesChart.data,
        }

      case '1':
        const obj = dataChart.dataListWordCloud.data.reduce(
          (acc, { title, words_cloud }) => {
            acc[title] = words_cloud
            return acc
          },
          {} as Record<string, any>,
        )
        return {
          ...responseDataWordCloud.option2,
          words_cloud: {
            ...obj,
            ...result,
          },
        }

      case '2':
        return dataChart.dataTimeLine.data

      default:
        return {}
    }
  }, [
    dataChartMore.data,
    values.analysis_menu,
    dataChart.wordLesChart.dataEchart,
    dataChart.wordLesChart.data,
    dataChart.dataListWordCloud.data,
    dataChart.dataTimeLine.data,
    responseDataWordCloud.option2,
  ])

  const fetchWordLesChart = useCallback(
    async (id: number) => {
      const params = {
        company_id: +id,
        type_of_process: +values.targetDocument,
        year: +values.targetYear,
        ...(values.quarters && { quarter: +values.quarters }),
      }
      try {
        const { data, hashed_data } =
          await companyServices.getWordLesChart(params)

        setDataChart(prev => ({
          ...prev,
          wordLesChart: {
            data: data?.word_cloud ?? [],
            dataEchart: data?.les_miserable ?? [],
            hashed_data: hashed_data ?? '',
          },
          dataListWordCloud: {
            data: [],
            hashed_data: '',
          },
          dataTimeLine: {
            data: [],
            hashed_data: '',
          },
        }))
        setIsLoadingDataChart(false)

        setResponseDataWordCloud(prev => ({ ...prev, option1: data }))
      } catch (error) {
        handleResetDataChart()
      }
    },
    [values.quarters, values.targetDocument, values.targetYear],
  )

  const fetchWordCategoryChart = useCallback(
    async (id: number) => {
      const params = {
        year: +values.targetYear,
        ngram: +values.number_of_word,
        company_id: +id,
        type_of_process: +values.targetDocument,
        ...(values.quarters && { quarter: +values.quarters }),
      }
      try {
        const { data, hashed_data } =
          await companyServices.getWordCategoryChart(params)

        const convertData2 = Object.entries(data.words_cloud)
          .filter(([, words]) => words.length)
          .map(([key, words]) => ({ title: key, words_cloud: words }))

        const arrCompany = data.companies_ability_to_compare.map(o => ({
          label: Object.keys(o)[0],
          value: o[Object.keys(o)[0]],
        }))

        setMultiCompanyOptions(arrCompany)
        setDataChart(prev => ({
          ...prev,
          wordLesChart: {
            data: [],
            dataEchart: [],
            hashed_data: '',
          },
          dataListWordCloud: {
            data: convertData2,
            hashed_data: hashed_data ?? '',
          },
          dataTimeLine: {
            data: [],
            hashed_data: '',
          },
        }))
        setIsLoadingDataChart(false)

        setResponseDataWordCloud(prev => ({ ...prev, option2: data }))
      } catch (error) {
        handleResetDataChart()
      }
    },
    [
      values.quarters,
      values.targetYear,
      values.targetDocument,
      values.number_of_word,
    ],
  )

  const fetchWordTimeLineChart = useCallback(
    async (id: number) => {
      const params = {
        year: +values.targetYear,
        ngram: +values.number_of_word,
        company_id: +id,
        previous_times: +values.term,
        type_of_process: +values.targetDocument,
        ...(values.quarters && { quarter: +values.quarters }),
      }
      try {
        const { data: data3, hashed_data: hashed_data_3 } =
          await companyServices.getWordTimeLineChart(params)

        const convertData = data3.sort((a, b) => {
          if (values.sort_by === 1) {
            return a.index - b.index
          } else {
            return b.index - a.index
          }
        })

        setDataChart(prev => ({
          ...prev,
          wordLesChart: {
            data: [],
            dataEchart: [],
            hashed_data: '',
          },
          dataListWordCloud: {
            data: [],
            hashed_data: '',
          },
          dataTimeLine: {
            data: convertData,
            hashed_data: hashed_data_3 ?? '',
          },
        }))
        setIsLoadingDataChart(false)

        setResponseDataWordCloud(prev => ({ ...prev, option3: data3 }))
      } catch (error) {
        handleResetDataChart()
      }
    },
    [
      values.term,
      values.sort_by,
      values.quarters,
      values.targetYear,
      values.number_of_word,
      values.targetDocument,
    ],
  )

  const fetchDataChart = useCallback(async () => {
    if (!id || !isExplanation) return

    setDataChart(prev => ({
      ...prev,
      wordLesChart: {
        data: [],
        dataEchart: [],
        hashed_data: '',
      },
      dataListWordCloud: {
        data: [],
        hashed_data: '',
      },
      dataTimeLine: {
        data: [],
        hashed_data: '',
      },
    }))
    setIsLoadingDataChart(true)

    switch (values.analysis_menu) {
      case '0':
        fetchWordLesChart(+id)
        break
      case '1':
        fetchWordCategoryChart(+id)
        break
      case '2':
        fetchWordTimeLineChart(+id)

        break

      default:
        break
    }
  }, [
    fetchWordCategoryChart,
    fetchWordLesChart,
    fetchWordTimeLineChart,
    id,
    isExplanation,
    values.analysis_menu,
  ])

  const handleOffSwitch = () => {
    setValueSwitch(false)
    setValueExplanation(prev => ({
      ...prev,
      loading: false,
      data: '',
      done: true,
    }))

    abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()
  }

  const fetchMoreWordCloud = useCallback(async () => {
    if (!id || !values.multi_company) return

    // Compare the value selected with the data and remove outdated data
    const labelSet = new Set(values.multi_company.map(itm => itm.label))

    const presentValue = dataChartMore.data.filter(itm =>
      labelSet.has(itm.title),
    )

    const presentValueInit = dataChartMoreInit.filter(itm =>
      labelSet.has(itm.title),
    )

    if (values.multi_company.length === presentValue.length) {
      setDataChartMore(prev => ({ ...prev, data: presentValue }))
      setDataChartMoreInit(presentValueInit)
      return
    }

    // Find value selected but no data and get data of this value
    const dataSet = new Set(dataChartMore.data.map(itm => itm.title))
    const newCompanyId = values.multi_company
      .filter(itm => !dataSet.has(itm.label))
      .map(itm => itm.value)
    try {
      setDataChartMore(prev => ({
        ...prev,
        loading: true,
        hashed_data: '',
      }))

      const params = {
        year: +values.targetYear,
        ngram: +values.number_of_word,
        quarter: +values.quarters,
        company_id: +id,
        type_of_process: +values.targetDocument,
      }

      const arrCompany = await Promise.all(
        newCompanyId.map(
          async item =>
            await companyServices.getWordCloudMoreChart({
              ...params,
              compare_to: +item,
            }),
        ),
      )

      let newData: {
        title: string
        words_cloud: { text: string; value: number }[]
      }[] = []
      let hashed_data = ''

      arrCompany.forEach(item => {
        let title = Object.keys(item.data)[0]

        let data = item.data[title].map(word => ({
          text: word.text,
          value: word.value,
        }))

        hashed_data = hashed_data + item.hashed_data
        newData = [...newData, { title: title, words_cloud: data }]
      })

      setDataChartMore(prev => ({
        ...prev,
        data: [...prev.data, ...newData],
        loading: false,
        hashed_data: hashed_data,
      }))
      setDataChartMoreInit(prev => [...prev, ...newData])
    } catch (error) {
      setDataChartMore(prev => ({
        ...prev,
        loading: false,
        data: [],
        hashed_data: '',
      }))
      setDataChartMoreInit([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    values.multi_company,
    values.targetYear,
    values.number_of_word,
    values.quarters,
    values.targetDocument,
  ])

  const handleSwitch = async () => {
    if (!infoCompany?.company_name) return

    if (valueSwitch) {
      handleOffSwitch()
    } else {
      if (valueExplanation.loading || isNotWordmap || !isExplanation) return

      setValueSwitch(true)
      setValueExplanation(prev => ({
        ...prev,
        loading: true,
        data: '',
        done: false,
      }))
    }
  }

  const handleResetDataChart = () => {
    setDataChart(prev => ({
      ...prev,
      wordLesChart: {
        dataEchart: [],
        data: [],
        hashed_data: '',
      },
      dataListWordCloud: {
        data: [],
        hashed_data: '',
      },
      dataTimeLine: {
        data: [],
        hashed_data: '',
      },
    }))
    setIsLoadingDataChart(false)
    setDataChartMore(prev => ({
      ...prev,
      data: [],
      loading: false,
      hashed_data: '',
    }))
  }

  const handleOpenZoom = (info: {
    isShowAll: boolean
    typeChart: string
    index: number | null
  }) => {
    setInfoModal(info)
    onOpenZoom()
  }

  const handleCloseZoom = () => {
    onCloseZoom()
  }

  const handleSaveImproveWordCloudData = (
    type: string,
    index: number,
    data: WordCloudType[],
  ) => {
    switch (type) {
      case 'wordLesChart':
        setDataChart(prev => ({
          ...prev,
          wordLesChart: { ...prev.wordLesChart, data: data },
        }))
        break
      case 'dataListWordCloud':
        let newValue = dataChart.dataListWordCloud.data.map((itm, idx) =>
          idx === index ? { title: itm.title, words_cloud: data } : itm,
        )
        setDataChart(prev => ({
          ...prev,
          dataListWordCloud: { ...prev.dataListWordCloud, data: newValue },
        }))
        break

      case 'dataTimeLine':
        let newValue1 = dataChart.dataTimeLine.data.map((itm, idx) =>
          idx === index
            ? { index: itm.index, title: itm.title, words_cloud: data }
            : itm,
        )
        setDataChart(prev => ({
          ...prev,
          dataTimeLine: { ...prev.dataTimeLine, data: newValue1 },
        }))
        break
      case 'dataChartMore':
        let newValue2 = dataChartMore.data.map((itm, idx) =>
          idx === index - dataChart.dataListWordCloud.data.length
            ? { title: itm.title, words_cloud: data }
            : itm,
        )
        setDataChartMore(prev => ({
          ...prev,
          data: newValue2,
        }))
        break

      default:
        break
    }
  }

  const handleBackToInitialWordCloudData = (type: string, index: number) => {
    switch (type) {
      case 'wordLesChart':
        setDataChart(prev => ({
          ...prev,
          wordLesChart: {
            ...prev.wordLesChart,
            data: responseDataWordCloud.option1?.word_cloud ?? [],
          },
        }))
        break
      case 'dataListWordCloud':
        const convertData = Object.entries(
          responseDataWordCloud.option2?.words_cloud ?? {},
        )
          .filter(([, words]) => words.length)
          .map(([key, words]) => ({ title: key, words_cloud: words }))

        const findData = convertData.find((itm, idx) => idx === index)

        if (findData) {
          let newValue = dataChart.dataListWordCloud.data.map((itm, idx) =>
            idx === index
              ? { title: itm.title, words_cloud: findData.words_cloud }
              : itm,
          )
          setDataChart(prev => ({
            ...prev,
            dataListWordCloud: { ...prev.dataListWordCloud, data: newValue },
          }))
        }
        break
      case 'dataTimeLine':
        const findData3 = responseDataWordCloud.option3.find(
          (itm, idx) => idx === index,
        )
        if (findData3) {
          const newValue3 = dataChart.dataTimeLine.data.map((itm, idx) =>
            idx === index
              ? {
                  index: itm.index,
                  title: itm.title,
                  words_cloud: findData3.words_cloud,
                }
              : itm,
          )
          setDataChart(prev => ({
            ...prev,
            dataTimeLine: { ...prev.dataTimeLine, data: newValue3 },
          }))
        }
        break
      case 'dataChartMore':
        const findData4 = dataChartMoreInit.find(
          (itm, idx) => idx + dataChart.dataListWordCloud.data.length === index,
        )

        if (findData4) {
          let newValue4 = dataChartMore.data.map((itm, idx) =>
            idx + dataChart.dataListWordCloud.data.length === index
              ? { title: itm.title, words_cloud: findData4.words_cloud }
              : itm,
          )
          setDataChartMore(prev => ({ ...prev, data: newValue4 }))
        }
        break

      default:
        break
    }
  }

  useEffect(() => {
    fetchCompanyInfo()
  }, [fetchCompanyInfo])

  useEffect(() => {
    fetchMoreWordCloud()
  }, [fetchMoreWordCloud])

  useEffect(() => {
    fetchDataChart()
  }, [fetchDataChart])

  useEffect(() => {
    if (!accessToken || isNotWordmap || !valueSwitch) return

    const newSocket = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}/api/v1/advisor/list_new_file/summary_image/ws_stream`,
    )

    const handleSocketOpen = async () => {
      console.log('WS connected')
      const messAuthen = {
        type: 'auth_req',
        data: { Authorization: `Bearer ${accessToken}` },
      }
      newSocket.send(JSON.stringify(messAuthen))

      let message = {
        type: 'stream_req',
        data: {
          data: payloadSendWebsocket,
          company: infoCompany?.company_name,
          type_image: fileType,
          hashed_data: hashedDataChart,
          type_of_process: +values.targetDocument,
        },
      }

      newSocket.send(JSON.stringify(message))
    }

    const handleSocketMessage = (event: { data: string }) => {
      const objMess = JSON.parse(event.data)
      if (objMess?.done === 1) {
        setValueExplanation(prev => ({
          ...prev,
          data: prev.data.replace(/```/g, ''),
          done: true,
        }))
      } else {
        switch (objMess.type) {
          case 'stream_res':
            setValueExplanation(prev => ({
              ...prev,
              loading: false,
              data: (prev.data + objMess?.data).replace(/```html/g, ''),
            }))
            break
          default:
            break
        }
      }
    }

    const handleSocketClose = () => {
      console.log('WebSocket is disconnected')
    }

    newSocket.addEventListener('open', handleSocketOpen)
    newSocket.addEventListener('close', handleSocketClose)
    newSocket.addEventListener('message', handleSocketMessage)

    return () => {
      newSocket.removeEventListener('open', handleSocketOpen)
      newSocket.removeEventListener('close', handleSocketClose)
      newSocket.removeEventListener('message', handleSocketMessage)
      newSocket.close()
    }
  }, [
    fileType,
    accessToken,
    valueSwitch,
    isNotWordmap,
    hashedDataChart,
    payloadSendWebsocket,
    values.analysis_menu,
    values.targetDocument,
    infoCompany?.company_name,
  ])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidthSize(document.documentElement.clientWidth)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    ...props,
    isZoom,
    formik,
    values,
    infoModal,
    dataChart,
    valueSwitch,
    infoCompany,
    isNotWordmap,
    dataChartMore,
    displaySizeChart,
    valueExplanation,
    isLoadingDataChart,
    multiCompanyOptions,
    containerChartWidth,
    containerChartHeight,
    setValues,
    handleSwitch,
    setDataChart,
    setFieldValue,
    setValueSwitch,
    handleOpenZoom,
    handleOffSwitch,
    handleCloseZoom,
    setDataChartMore,
    setValueExplanation,
    handleResetDataChart,
    handleSaveImproveWordCloudData,
    handleBackToInitialWordCloudData,
  }
}
