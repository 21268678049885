import { Flex, Text } from '@chakra-ui/react'
import { SelectField } from 'components/atoms'
import React, { FC, useMemo } from 'react'
import { optionNumberOfWord } from 'components/molecules/WordMap/data'
import { DataChartMoreType, DataChartType } from 'types/chart'
import { FormikErrors } from 'formik'
import { optionsSortBy } from '../constant'
import { FormValues } from '../hook'
import AiExplainAndZoomAll from '../AiExplainAndZoomAll'

type WordMapOptionsProps = {
  values: FormValues
  valueSwitch: boolean
  isOpenDrawer: boolean
  isNotWordmap: boolean
  handleSwitch: () => Promise<void>
  setDataChart: React.Dispatch<React.SetStateAction<DataChartType>>
  handleOffSwitch: () => void
  handleOpenZoom: (info: {
    isShowAll: boolean
    typeChart: string
    index: number | null
  }) => void
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormValues>>
  setDataChartMore: React.Dispatch<React.SetStateAction<DataChartMoreType>>
}

const WordMapOptions: FC<WordMapOptionsProps> = ({
  values,
  valueSwitch,
  isOpenDrawer,
  isNotWordmap,
  setDataChart,
  handleSwitch,
  setFieldValue,
  handleOpenZoom,
  handleOffSwitch,
  setDataChartMore,
}) => {
  const conditionShowExplain = isOpenDrawer && values.analysis_menu === '2'

  const optionDisplaySize = useMemo(() => {
    let data = [
      {
        label: '大',
        value: '0',
      },
      {
        label: '中',
        value: '1',
      },
    ]
    if (isOpenDrawer) {
      return data
    } else {
      return [
        ...data,
        {
          label: '小',
          value: '2',
        },
      ]
    }
  }, [isOpenDrawer])

  return (
    <Flex
      alignItems={'center'}
      gap={'4px'}
      justifyContent={conditionShowExplain ? 'space-between' : 'flex-end'}
      mb={'12px'}
    >
      <Flex>
        {values.analysis_menu === '2' && (
          <SelectField
            height="40px"
            isInForm={false}
            name="sort_by"
            options={optionsSortBy}
            placeholder="並び順"
            valueOption={optionsSortBy.find(it => it.value === values.sort_by)}
            width="106px"
            onChange={e => {
              setFieldValue('sort_by', e?.value)
            }}
          />
        )}
        <Flex gap={'4px'}>
          <Flex align={'center'} gap={1}>
            <Text
              color={'#6C7275'}
              fontSize="12px"
              fontWeight="normal"
              lineHeight="shorter"
              minW="fit-content"
            >
              表示サイズ
            </Text>
            <SelectField
              height="40px"
              isInForm={false}
              name="display_size"
              options={optionDisplaySize}
              placeholder=""
              valueOption={optionDisplaySize.find(
                it => it.value === values.display_size,
              )}
              width="73px"
              onChange={e => {
                setFieldValue('display_size', e?.value)
              }}
            />
          </Flex>
          <Flex align={'center'} gap={1}>
            <Text
              color={'#6C7275'}
              fontSize="12px"
              fontWeight="normal"
              lineHeight="shorter"
              minW="fit-content"
            >
              単語数
            </Text>
            <SelectField
              height="40px"
              isInForm={false}
              name="number_of_word"
              options={optionNumberOfWord}
              placeholder=""
              valueOption={optionNumberOfWord.find(
                it => it.value === values.number_of_word,
              )}
              width="85px"
              onChange={e => {
                if (values.number_of_word === e?.value) return
                setFieldValue('number_of_word', e?.value)
                setDataChart(prev => ({
                  ...prev,
                  wordLesChart: {
                    dataEchart: [],
                    data: [],
                    hashed_data: '',
                  },
                  dataTimeLine: {
                    data: [],
                    hashed_data: '',
                  },
                  loading: false,
                }))
                setDataChartMore(prev => ({
                  ...prev,
                  data: [],
                  loading: false,
                  hashed_data: '',
                }))
                handleOffSwitch()
              }}
            />
          </Flex>
        </Flex>
      </Flex>
      {conditionShowExplain && (
        <AiExplainAndZoomAll
          handleOpenZoom={handleOpenZoom}
          handleSwitch={handleSwitch}
          isNotWordmap={isNotWordmap}
          isShowZoomAll={values.analysis_menu !== '0'}
          valueSwitch={valueSwitch}
        />
      )}
    </Flex>
  )
}

export default WordMapOptions
