import { axiosWithFile, axiosWithToken } from 'libs/configureAxios'
import { BasicResponse } from 'types/conmon'
import {
  CompanyFavoriteRequest,
  CompanyFavoriteResponse,
  CompanyInfoRequest,
  CompanyInfoResponse,
  CompanyListRequest,
  CompanyRankingRequest,
  CompanyRankingResponse,
  CompanyRegistrationType,
  CompanyResponse,
  CompanySearchRequest,
  CompanySearchResponse,
  CorporateRankingRequest,
  CorporateRankingResponse,
  ImproveWordmapBodyRequest,
  OptionsContactPersonResponse,
  OptionsIndustryResponse,
  OptionsProductResponse,
  ReportYearQuartersRequest,
  ReportYearQuartersResponse,
  UploadChartImageToChatGPTRequest,
  WordCategoryChartRequest,
  WordCategoryChartResponse,
  WordCloudMoreChartResponse,
  WordLesChartRequest,
  WordLesChartResponse,
  WordTimeLineChartRequest,
  WordTimeLineChartResponse,
} from './companyServices.type'

/**
 * @returns function that handle company services
 */
export class CompanyServices {
  /**
   * @returns function that get company list
   */
  async getCompanyList(
    size?: string,
    params?: CompanyListRequest,
    controller?: AbortController,
  ): Promise<BasicResponse<CompanyResponse[]>> {
    return (
      await axiosWithToken.get(`/v1/${size}/company`, {
        params,
        signal: controller?.signal,
      })
    ).data
  }

  /**
   * @returns function that get company detail
   */
  async getCompanyDetail(
    size: string,
    params: {
      id: number
    },
  ): Promise<BasicResponse<CompanyResponse>> {
    return (await axiosWithToken.get(`/v1/${size}/company/${params.id}`)).data
  }

  /**
   * @returns function that delete multi company
   */
  async deleteMultiCompany(ids: number[]) {
    return await axiosWithToken.delete(`/v1/admin/company/`, { data: { ids } })
  }

  /**
   * @returns function that create company
   */
  async createCompany(body: CompanyRegistrationType) {
    return await axiosWithToken.post(`/v1/admin/company/`, body)
  }

  /**
   * @returns function that create company
   */
  async updateCompany(id: string, body: CompanyRegistrationType) {
    return await axiosWithToken.put(`/v1/admin/company/${id}`, body)
  }

  /**
   * @returns function that create company
   */
  async uploadCsvCompany(
    body: FormData,
  ): Promise<BasicResponse<{ err: number[]; ok: number[] }>> {
    return await axiosWithFile.post('/v1/admin/company/import', body)
  }

  async linkSFA(ids: number[], override: boolean) {
    return await axiosWithToken.post(`/v1/admin/company/sync_sales_force`, {
      companies_id: ids,
      override: override,
    })
  }

  /**
   * @returns function that get options industry of corporate ranking
   */
  async getOptionsIndustry(): Promise<
    BasicResponse<OptionsIndustryResponse[]>
  > {
    return (await axiosWithToken.get(`/v1/advisor/company/industry_name/`)).data
  }

  /**
   * @returns function that get options product of corporate ranking
   */
  async getOptionsProduct(params: {
    page: number
  }): Promise<BasicResponse<OptionsProductResponse[]>> {
    return (
      await axiosWithToken.get(`/v1/advisor/product`, {
        params: { page: params.page, page_size: 5 },
      })
    ).data
  }

  /**
   * @returns function that get options contact person of corporate ranking
   */
  async getOptionsContactPerson(params: {
    page: number
  }): Promise<BasicResponse<OptionsContactPersonResponse[]>> {
    return (
      await axiosWithToken.get(`/v1/advisor/company/contact_person_name/`, {
        params: { page: params.page, page_size: 5 },
      })
    ).data
  }

  /**
   * @returns function that get data corporate ranking
   */
  async getCorporateRanking(
    params: CorporateRankingRequest,
  ): Promise<BasicResponse<CorporateRankingResponse[]>> {
    return (
      await axiosWithToken.get(`/v1/advisor/company/ranking/`, {
        params,
      })
    ).data
  }

  /**
   * @returns function that get company list
   */
  async getCompanyFavorite(
    params: CompanyFavoriteRequest,
  ): Promise<BasicResponse<CompanyFavoriteResponse[]>> {
    return (
      await axiosWithToken.get(`/v1/advisor/company_favorite`, {
        params,
      })
    ).data
  }

  /**
   * @returns function that get company detail
   */
  async geCompanyFavoriteDetail(params: {
    id: string
  }): Promise<BasicResponse<{ is_favorite: boolean }>> {
    return (
      await axiosWithToken.get(
        `/v1/advisor/company_favorite/company/${params.id}`,
      )
    ).data
  }

  /**
   * @returns function that create company
   */
  async addCompanyFavorite(body: { company_id: string }) {
    return await axiosWithToken.post(`/v1/advisor/company_favorite/`, body)
  }

  /**
   * @returns function that create company
   */
  async removeCompanyFavorite(body: { company_id: string }) {
    return await axiosWithToken.delete(`/v1/advisor/company_favorite/`, {
      data: body,
    })
  }

  /**
   * @returns function that create company
   */
  async changeHistorySearchCompany(body: { company_id: string | number }) {
    return await axiosWithToken.post(`/v1/advisor/company_search_log/`, body)
  }

  /**
   * @returns function that get company list
   */
  async getCompanySearch(
    params: CompanySearchRequest,
  ): Promise<BasicResponse<CompanySearchResponse[]>> {
    return (
      await axiosWithToken.get(`/v1/advisor/company_search_log`, {
        params,
      })
    ).data
  }

  /**
   * @returns function that get company list
   */
  async getCompanyInfo(
    params: CompanyInfoRequest,
  ): Promise<BasicResponse<CompanyInfoResponse>> {
    return (
      await axiosWithToken.get(`/v1/advisor/company/info/`, {
        params,
      })
    ).data
  }

  /**
   * @returns function that get company list
   */
  async getCompanyRanking(
    params: CompanyRankingRequest,
  ): Promise<BasicResponse<CompanyRankingResponse[]>> {
    return (
      await axiosWithToken.get(`/v1/advisor/company/ranking/`, {
        params,
      })
    ).data
  }

  /**
   * @returns function that get company list
   */
  async getStates(): Promise<
    BasicResponse<{ id: string; name_en: string; name_jp: string }[]>
  > {
    return (await axiosWithToken.get(`/v1/common/settings/states`)).data
  }

  /**
   * @returns function that get company list
   */
  async getCompanyCategories(): Promise<
    BasicResponse<{ id: number; name: string }[]>
  > {
    return (await axiosWithToken.get(`/v1/common/settings/company/categories`))
      .data
  }

  /**
   * @returns function that get company list
   */
  async getCompanyKindOffice(): Promise<
    BasicResponse<{ id: number; name: string }[]>
  > {
    return (await axiosWithToken.get(`/v1/common/settings/company/kind_office`))
      .data
  }

  /**
   * @returns function that get company list
   */
  async getCompanyPositions(): Promise<
    BasicResponse<{ id: number; name: string }[]>
  > {
    return (await axiosWithToken.get(`/v1/common/settings/company/positions`))
      .data
  }

  /**
   * @returns function that get company list
   */
  async getCities(state_id: number): Promise<
    BasicResponse<
      {
        created_at: string
        updated_at: string
        id: number
        name_en: string
        name_jp: string
        state_id: number
      }[]
    >
  > {
    return (
      await axiosWithToken.get(`/v1/common/settings/state/${state_id}/cities`)
    ).data
  }

  /**
   * @returns function that get company list
   */
  async getAllCities(): Promise<
    BasicResponse<
      {
        created_at: string
        updated_at: string
        id: number
        name_en: string
        name_jp: string
        state_id: number
      }[]
    >
  > {
    return (await axiosWithToken.get(`/v1/common/settings/cities`)).data
  }

  /**
   * @returns function that get company list
   */
  async getSeed(params: {
    master_key: string
    seed_all: boolean
    seed_dt_company_list: boolean
    seed_list_news: boolean
    seed_list_news_ai_original_company: boolean
    seed_list_news_ai: boolean
    seed_for_admin_email: string
    seed_consultation: boolean
    seed_ai_conversation: boolean
    seed_news_prompt: boolean
    seed_products: boolean
    seed_list_news_ai_original_keyword: boolean
  }): Promise<BasicResponse<{ is_success: boolean; message: string }>> {
    return (await axiosWithToken.get(`/v1/common/seed_data/seed`, { params }))
      .data
  }

  /**
   * @returns function that get report year quarters
   */
  async getReportYearQuarters(
    params: ReportYearQuartersRequest,
  ): Promise<BasicResponse<ReportYearQuartersResponse>> {
    return (
      await axiosWithToken.get(
        `/v1/advisor/company/${params.company_id}/helper/available_years_quarters/`,
        { params },
      )
    ).data
  }

  /**
   * @returns function that get meta analyze word les
   */
  async getWordLesChart(
    params: WordLesChartRequest,
  ): Promise<BasicResponse<WordLesChartResponse>> {
    return (
      await axiosWithToken.get(
        `/v1/advisor/company/${params.company_id}/meta_analyze/word_les_set/`,
        { params },
      )
    ).data
  }

  /**
   * @returns function that get meta analyze categories
   */
  async getWordCategoryChart(
    params: WordCategoryChartRequest,
  ): Promise<BasicResponse<WordCategoryChartResponse>> {
    return (
      await axiosWithToken.get(
        `/v1/advisor/company/${params.company_id}/meta_analyze/categories/`,
        { params },
      )
    ).data
  }

  /**
   * @returns function that get meta analyze timeline
   */
  async getWordTimeLineChart(
    params: WordTimeLineChartRequest,
  ): Promise<BasicResponse<WordTimeLineChartResponse[]>> {
    return (
      await axiosWithToken.get(
        `/v1/advisor/company/${params.company_id}/meta_analyze/timeline/`,
        { params },
      )
    ).data
  }

  /**
   * @returns function that get meta analyze categories
   */
  async getWordCloudMoreChart(
    params: WordCategoryChartRequest,
  ): Promise<BasicResponse<WordCloudMoreChartResponse>> {
    return (
      await axiosWithToken.get(
        `/v1/advisor/company/${params.company_id}/meta_analyze/categories/compare_to/`,
        { params },
      )
    ).data
  }

  /**
   * @returns function that upload chart image
   */
  async uploadChartImageToChatGPT(
    params: UploadChartImageToChatGPTRequest,
    body: FormData,
    signal: any,
  ) {
    return await axiosWithFile.post(
      `/v1/advisor/list_new_file/summary_images/`,
      body,
      {
        params,
        signal,
      },
    )
  }

  /**
   * @returns function improve wordmap
   */
  async getImproveWordmap(body: ImproveWordmapBodyRequest): Promise<
    BasicResponse<{
      chart_data: {
        text: string
        value: number
      }[]
    }>
  > {
    return (
      await axiosWithToken.post(`/v1/common/settings/improve_wordcloud`, body)
    ).data
  }
}
