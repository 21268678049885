import { CircularProgress, Flex, SimpleGrid, Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import { DataChartMoreType, DataChartType } from 'types/chart'
import WordMapChart from 'components/molecules/WordMapChart'
import { WordCloudType } from 'services/companyServices'
import { FormValues } from '../hook'

type RenderWordMapProps = {
  values: FormValues
  isLoading: boolean
  isNotWordmap: boolean
  dataChart: DataChartType
  displaySizeChart: number
  containerChartWidth: number
  containerChartHeight: number
  dataChartMore: DataChartMoreType
  handleOpenZoom: (info: {
    isShowAll: boolean
    typeChart: string
    index: number | null
  }) => void
  handleBackToInitialWordCloudData: (type: string, index: number) => void
  handleSaveImproveWordCloudData: (
    type: string,
    index: number,
    data: WordCloudType[],
  ) => void
  companyName: string | undefined
  industryName: string | undefined
  reportType: number
}

const RenderWordMap: FC<RenderWordMapProps> = ({
  values,
  isLoading,
  dataChart,
  isNotWordmap,
  dataChartMore,
  displaySizeChart,
  containerChartWidth,
  containerChartHeight,
  handleOpenZoom,
  companyName,
  industryName,
  handleBackToInitialWordCloudData,
  handleSaveImproveWordCloudData,
  reportType,
}) => {
  return (
    <>
      {isNotWordmap && !isLoading && (
        <Text
          color={'black.100'}
          fontSize={'16px'}
          textAlign={'center'}
          w="full"
        >
          データがありません。
        </Text>
      )}
      {isLoading && (
        <Flex alignItems={'center'} h={'150px'} justify={'center'} w={'full'}>
          <CircularProgress isIndeterminate color="blue.900" size={10} />
        </Flex>
      )}
      {values.analysis_menu === '0' && !isNotWordmap && (
        <SimpleGrid columns={1} spacingX="16px" spacingY="4px">
          {/* <Box w={`${containerChartWidth}px`}>
            <Flex align={'center'} justify={'space-between'} mb={'8px'}>
              <ChartWrapper
                handleOpenZoom={() => {
                  handleOpenZoom({
                    isShowAll: false,
                    typeChart: 'wordcloud',
                    index: 0,
                  })
                }}
                isBorderLeft={true}
                isShowButtonZoom={false}
                label="ワードマップ"
                setValueSwitch={setValueSwitch}
                valueSwitch={valueSwitch}
              />
            </Flex>
            <Box
              height={`${containerChartHeight}px`}
              width={`${containerChartWidth}px`}
            >
              <WordCloud
                height={containerChartHeight}
                width={containerChartWidth}
                words={dataChart.wordLesChart.data}
              />
            </Box>
          </Box> */}
          <WordMapChart
            key={'重要なワードを見える化'}
            companyName={companyName}
            data={{
              title: '重要なワードを見える化',
              words_cloud: dataChart.wordLesChart.data,
            }}
            handleBackToInitialWordCloudData={() =>
              handleBackToInitialWordCloudData('wordLesChart', 0)
            }
            handleOpenZoom={() =>
              handleOpenZoom({
                isShowAll: false,
                typeChart: 'wordcloud',
                index: 0,
              })
            }
            handleSaveImproveWordCloudData={data =>
              handleSaveImproveWordCloudData('wordLesChart', 0, data)
            }
            height={containerChartHeight}
            industryName={industryName}
            isShowButtonZoom={true}
            ngram={values.number_of_word}
            typeOfProcess={String(reportType)}
            width={containerChartWidth}
          />
          {/* {Boolean(!dataChart.wordLesChart.dataEchart.length) ? (
              <></>
            ) : isLoading ? (
              <Box
                bgColor={'#FEFEFE'}
                borderRadius={'16px'}
                h={'396px'}
                mb="8px"
                w={'full'}
              ></Box>
            ) : (
              <Box
                w={
                  boxRef.current
                    ? isOpenDrawer
                      ? `calc(${widthChart - 32})`
                      : `calc(${widthChart - 40} / 2)`
                    : '100%'
                }
              >
                <Flex align={'center'} justify={'space-between'}>
                  <ChartWrapper
                    handleOpenZoom={() => {
                      handleOpenZoom({
                        isShowAll: false,
                        typeChart: 'echart',
                        index: null,
                      })
                    }}
                    isBorderLeft={true}
                    label="共起ネットワーク"
                  />
                </Flex>
                <Box h={550}>
                  <Echarts
                    ref={el => (ref.current[1] = el)}
                    data={dataChart.wordLesChart.dataEchart}
                    reloadChart={reloadChart}
                  />
                </Box>
              </Box>
            )} */}
        </SimpleGrid>
      )}
      {values.analysis_menu !== '0' && !isNotWordmap && (
        <SimpleGrid columns={displaySizeChart} spacingX="16px" spacingY="4px">
          {(values.analysis_menu === '1'
            ? dataChart.dataListWordCloud.data
            : dataChart.dataTimeLine.data
          ).map((item, index) => (
            <WordMapChart
              key={item.title}
              companyName={companyName}
              data={item}
              handleBackToInitialWordCloudData={() =>
                handleBackToInitialWordCloudData(
                  values.analysis_menu === '1'
                    ? 'dataListWordCloud'
                    : 'dataTimeLine',
                  index,
                )
              }
              handleOpenZoom={() => {
                handleOpenZoom({
                  isShowAll: false,
                  typeChart: 'wordcloud',
                  index: index,
                })
              }}
              handleSaveImproveWordCloudData={data =>
                handleSaveImproveWordCloudData(
                  values.analysis_menu === '1'
                    ? 'dataListWordCloud'
                    : 'dataTimeLine',
                  index,
                  data,
                )
              }
              height={containerChartHeight}
              industryName={industryName}
              ngram={values.number_of_word}
              typeOfProcess={String(reportType)}
              width={containerChartWidth}
            />
          ))}
          {dataChartMore.data.map((item, index) => (
            <WordMapChart
              key={item.title}
              companyName={companyName}
              data={item}
              handleBackToInitialWordCloudData={() =>
                handleBackToInitialWordCloudData(
                  'dataChartMore',
                  dataChart.dataListWordCloud.data.length + index,
                )
              }
              handleOpenZoom={() => {
                handleOpenZoom({
                  isShowAll: false,
                  typeChart: 'wordcloud',
                  index: dataChart.dataListWordCloud.data.length + index,
                })
              }}
              handleSaveImproveWordCloudData={data =>
                handleSaveImproveWordCloudData(
                  'dataChartMore',
                  dataChart.dataListWordCloud.data.length + index,
                  data,
                )
              }
              height={containerChartHeight}
              industryName={industryName}
              ngram={values.number_of_word}
              typeOfProcess={String(reportType)}
              width={containerChartWidth}
            />
          ))}
        </SimpleGrid>
      )}
    </>
  )
}

export default RenderWordMap
